@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700|Raleway:200,300,400,500,600,700,800');
/* # CSS */

*{
    padding: 0;
    margin: 0;  
    outline: none;
    border: none;
}
body{
    font-family:apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f6f6f6;
  margin: 0;
}
.padding {
    padding: 7.5rem 0;
}
.padding_top {
    padding-top: 7.5rem;
}
.bottom20 {
    margin-bottom: 20px;
}
.font-light {
    font-weight: 300;
}
.fontbold {
    font-weight: bold;
}
.bottom25 {
    margin-bottom: 25px;
}
.bottom15 {
    margin-bottom: 15px;
}
.whitecolor {
    color: #ffffff;
}
.margin_bottom {
    margin-bottom: 7.5rem;
}
.text-center {
    text-align: center!important;
}
.justify-content-center {
    -webkit-box-pack: center!important;
    -ms-flex-pack: center!important;
    justify-content: center!important;
}
.container{
    padding: 0px 15px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
}
.navbar-wrapper{
    background-color: white;
    width: 100%;
    /* box-shadow: 0 5px 13px 0 rgba(228,228,228,.75); */
    /* box-shadow: 0 1px 20px 0 rgba(46,61,73,.2); */
    box-shadow:0 10px 10px -10px rgba(0, 0, 0, 0.35);
    position: fixed;
    z-index: 100;
    -webkit-animation-name: stickySlideDown;
          animation-name: stickySlideDown;
}
.navbar-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 3%;
}
.navbar{
    margin-left: 10px;
    padding: 0;
}
.nav{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    list-style: none;
    overflow: hidden;
	transition-property: all;
	transition-duration: .5s;
	transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}
.nav-list{
    padding-top:8px; 
    padding-bottom:8px; 
    padding-left:15px; 
    padding-right:15px; 
    z-index: 1;
}
.nav-link-custom{
    text-decoration: none !important;
    text-align: center;
    text-transform: capitalize;
    cursor: pointer !important;
    font-size: 14px;
    z-index: 1;
    color: #212529 !important;
    font-weight: 500
}
.nav-link-custom:hover{
    /* color: #643094; */
    color: #013d73;
}
.logo{
    width: 100px;
}
.hide{
    display: none;
}

.menu-button{
    cursor: pointer;
    padding: 5px;
}
.menu-button div{
    width: 25px;
    height: 2px;
    display: none;
    background-color: #222222;
    background: linear-gradient(90deg, #643094, #00bcd4);
    margin: 4px 0px;
    transition: 0.4s;
}
.change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-4px, 3px);
  transform: rotate(-45deg) translate(-4px, 3px);
}
.change .bar2 {
    opacity: 0;
}
.change .bar3 {
  -webkit-transform: rotate(45deg) translate(-5px, -4px);
  transform: rotate(45deg) translate(-5px, -4px);
}

.dropdown-menu-custom {
  position:absolute;
  background:#fff;
  text-align:left;
  display:none;
  min-width: 10rem;
  z-index: 1;
  margin-top: 10px;
  background-clip:padding-box;
  border:1px solid rgba(0,0,0,.15);
  border-top: 3px solid #013d73;
}

.dropdown-menu-custom:after {
    content: '';
    display: inline-block;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #013d73;
    position: absolute;
    top: -7px;
    left: 15px;
}

.nav-list:hover > .dropdown-menu-custom{
  display:block;
}

.dropdown-list {
    list-style: none;
    display: flex;
}

.dropdown-link{
    font-size: 12px;
    text-decoration: none !important;
    cursor: pointer !important;
    padding: .5rem 1.5rem;
    color: #4d4c69;
    font-weight: 500;
    width: 100%;
    text-transform: uppercase;
}

.dropdown-link:hover{
    color:#fff !important;
    background-color: #013d73 !important;
}

.dropdown-divider {
    height: 0;
    margin: 0 !important;
    overflow: hidden;
    border-top: 1px solid #e9ecef;
}

@-webkit-keyframes stickySlideDown {
  0% {
    opacity: 0.7;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes stickySlideDown {
  0% {
    opacity: 0.7;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

/* Latest Audios */
.progress-bar{
    background-color:transparent !important;
}

.button {
  -webkit-appearance: none;
          appearance: none;
  -webkit-filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
          filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
  -webkit-transition: all .5s ease;
         filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
  -webkit-transition: all .5s ease;
          transition: all .5s ease;
  border: none;
  background: #013d73;
  color: #FFFFFF;
  border-radius: 30px;
  text-transform: uppercase;
  box-sizing: border-box;
  padding: 15px 40px;
  font-weight: 400;
  cursor: pointer;
  font-size: 13px;
}

.carousel-button{
  cursor: pointer;
}

.button:hover {
  color: #FFFFFF;
  background: #222222;
  -webkit-filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
          filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
}

.audios-card-audio{
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    background-size: cover;
    justify-content: center;
    background-image: url("./images/bg1.jpg");
    background-repeat: no-repeat;
}

.book-img-wrapper div{
    margin:0px auto;
}

/* We offfers */

#video-parallax {
    background: url(./images/weOffer.jpg) no-repeat;
    background-position: center center;
    -webkit-background-size: cover;
    background-size: cover;
    background-attachment: fixed;
    position: relative;
    width: 100%;
    margin-top: 30px;
}

.process-wrapp {
    overflow: hidden;
    display: table;
    width: 100%;
    table-layout: fixed;
    /* margin: 0 15px; */
    padding: 0;
    list-style: none;
}
.process-wrapp li {
    text-align: center;
    padding: 70px 15px 0 15px;
    display: table-cell;
}
.process-wrapp li:first-child::before, .process-wrapp li:last-child::after {
    opacity: 0;
    visibility: hidden;
}
.process-wrapp li::before {
    left: 0;
}
.process-wrapp li::after {
    right: 0;
}
.process-wrapp li::before, .process-wrapp li::after {
    /* content: ""; */
    height: 1px;
    top: 115px;
    background: rgba(255,255,255, .5);
    position: absolute;
    width: 50%;
    z-index: -1;
    margin: 0 -45px;
}
.process-wrapp, 
.process-wrapp li, 
.process-wrapp li > .pro-step{
    position: relative;
    z-index: 1;
}
.process-wrapp li > .pro-step {
    display: inline-block;
    height: 90px;
    width: 90px;
    line-height: 90px;
    border: 2px solid #fff;
    font-size: 28px;
    font-weight: 600;
    color: #fff;
    text-align: center;
    -webkit-border-radius: 50%;
    border-radius: 50%;
}
.process-wrapp li p:last-child {
    font-size: 13px;
    color: #fff;
}
.process-wrapp li:hover {
    cursor: pointer;
}
.process-wrapp li:hover > .pro-step {
   -webkit-box-shadow: 0 0 25px 50px #fff inset;
   -ms-box-shadow: 0 0 25px 50px #fff inset;
   -o-box-shadow: 0 0 25px 50px #fff inset;
   -moz-box-shadow: 0 0 25px 50px #fff inset;
   box-shadow: 0 0 25px 50px #fff inset;
   color: #013d73;
}

.button-semi-transparent,
.button-semi-white, 
.button-border-color{
    display: block;
    width: 200px;
    padding: 8px;
    color: #fff;
    background-color: transparent;
    margin: 40px auto 0px auto;
    border: 2px solid #fff;
    text-align: center;
    outline: none;
    text-decoration: none;
    -webkit-transition:  background-color cubic-bezier(0.39, 0.575, 0.565, 1) 0.5s,  border-color cubic-bezier(0.39, 0.575, 0.565, 1)  0.5s;
    transition: background-color cubic-bezier(0.39, 0.575, 0.565, 1) 0.5s,  border-color cubic-bezier(0.39, 0.575, 0.565, 1)  0.5s;
}

.button-semi-transparent:hover, 
.button-semi-transparent:active {
    background-color: #013d73;
    border: 2px solid #013d73;
    color: #fff !important;
    /* background-color: rgba(1, 61, 115, 0.5); */
    border-color: #013d73;
    -webkit-transition:  background-color cubic-bezier(0.39, 0.575, 0.565, 1) 0.5s,  border-color cubic-bezier(0.39, 0.575, 0.565, 1)  0.5s;
    transition: background-color cubic-bezier(0.39, 0.575, 0.565, 1) 0.5s,  border-color cubic-bezier(0.39, 0.575, 0.565, 1)  0.5s;
}

.button-semi-white:hover, 
.button-semi-white:active {
    background-color: #fff;
    border: 2px solid #fff;
    color: #000 !important;
    border-color: #fff;
    -webkit-transition:  background-color cubic-bezier(0.39, 0.575, 0.565, 1) 0.5s,  border-color cubic-bezier(0.39, 0.575, 0.565, 1)  0.5s;
    transition: background-color cubic-bezier(0.39, 0.575, 0.565, 1) 0.5s,  border-color cubic-bezier(0.39, 0.575, 0.565, 1)  0.5s;
}

.button-border-color:hover, 
.button-border-color:active {
    color: #013d73;
    border-color: #013d73;
    -webkit-transition: border-color 0.4s ease-in, color 0.4s ease-in;
    transition: border-color 0.4s ease-in, color 0.4s ease-in;
}

/* latest books */

.book-img-wrapper{
    width: 100%;
    height: 340px;
    background-size: contain;
    padding: 20px 0px;
    background-repeat: no-repeat;
    background-image: url('./images/loader.gif');
}

.book-title{
    width: 100%;
    font-size:21px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-bottom: 0px !important;
}

.book-author{
    width: 100%;
    color:#5d5c5c;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-bottom: 0px !important;
}

/* text section */

.fact-iconic, .counter-photo{
    position: relative;
}

.text-content-msg-wrapper h3::after {
    content: "";
    height: 3px;
    width: 50px;
    background: #ffffff;
    display: block;
    margin-top: 15px;
}

.text-section{
    position: relative;
    margin-top: 50px;
    background: linear-gradient(90deg, #4d4c69 31%, #013d73 69%);
    padding:70px 10px;
}

.text-content-msg-wrapper{
    padding:40px 10px;
    color:#fff;
}

.icon-counters {
    display: inline-block;
    padding: 20px 15px;
    text-align: center;
}

.icon-counters .img-icon {
    text-align: center;
    font-size: 40px;
    height: 70px;
    width: 70px;
    line-height: 70px;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    -webkit-transition: all .7s ease-in .3s;
    -o-transition: all .7s ease-in .3s;
    transition: all .7s ease-in .3s;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
}

.icon-counters:hover .img-icon {
    -webkit-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    transform: rotateY(180deg);
}

.counters-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.counters .count_nums {
    font-size: 1.875rem;
    margin-right: 4px;
}

.icon-counters .img-icon, .counters .count_nums, .counters i {
    display: inline-block;
    vertical-align: middle;
}

.text-section-title {
    font-size: 1rem;
}

.fact-iconic h3, .counters .count_nums {
    font-weight: bold;
}

.we-offer{
    margin-bottom: 27px; 
    font-size: 45px; 
    color: #fff; 
    text-align: center;
}

.event-card{
    width: 100%;
    height: 410px;
    position:relative;
    background-size: cover;
    background-position: center center;
}
.event-card::after {
    content: "";
    background: rgba(0,0,0,.5);
    color: #fff;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}
.event-bg{
    z-index: 1;
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: #fff;
}

.contact-wrapper{
    padding:10px 3% 0px 0px;
}

.contact-wrapper h5{
    color: #222;
    font-weight: bold;
}

.location-name{
    color: #222;
    font-size: 15px;
    margin-top: 20px;
    font-weight: bold;
    margin-bottom:0px;
}

.location-address{
    color: #222;
    margin-bottom:10px;
    font-weight: 400;
}

.location-link{
    font-size: 13px;
    color: #013d73;
    font-weight: 800;
    display: inline-block;
    text-decoration: none;
    background: transparent;
    text-transform: uppercase;
    transition: all .3s ease;
}

.location-link:hover, .location-link:focus {
    color: #4d4c69;
    text-decoration: none;
    transition: all .3s ease;
}

.contact{
    width: 100%;
}

.contact .form-control{
    border: none;
    border-bottom: 1px solid #a5a5a5;
    padding: 12px 0;
    background: #fff;
    -webkit-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #000000;
    margin-bottom: 30px;
    font-size: 16px;
    position: relative;
    -webkit-transition: border .9s ease;
    -o-transition: border .9s ease;
    transition: border .9s ease;
}

.contact .form-control:focus {
    border-bottom: 1px solid #212331;
}

.contact .request-button{
    width:100%;
    position: relative;
    display: inline-block;
    font-size: 13px;
    padding: 1rem 1.6rem;
    line-height: 1;
    text-transform: uppercase;
    text-align: center;
    background-color: #013d73;
    color: #fff;
    font-weight: bold;
    z-index: 1;
    border: 1px solid #013d73;
    margin: 0px auto;
    overflow: hidden;
    -webkit-transition:  background-color cubic-bezier(0.39, 0.575, 0.565, 1) 0.5s,  border-color cubic-bezier(0.39, 0.575, 0.565, 1)  0.5s;
    transition: background-color cubic-bezier(0.39, 0.575, 0.565, 1) 0.5s,  border-color cubic-bezier(0.39, 0.575, 0.565, 1)  0.5s;
}

.contact .request-button:hover, 
.contact .request-button:active {
    color: #fff;
    background-color: #013d73;
    border-color: #013d73;
    transition: background-color cubic-bezier(0.39, 0.575, 0.565, 1) 0.5s, border-color cubic-bezier(0.39, 0.575, 0.565, 1) 0.5s;
    -webkit-transition: background-color cubic-bezier(0.39, 0.575, 0.565, 1) 0.5s, border-color cubic-bezier(0.39, 0.575, 0.565, 1) 0.5s;
}

.map-container{
    padding: 10px;
}

.map-style {
    width: 100%;
    height: 450px;
    border-width: 1px;
    border-style: solid;
    border-bottom-color: #fbbc05;
    border-top-color: #4285f4;
    border-right-color: #ea4335;
    border-left-color: #34a853;
}

.padding-half {
    padding: 2.375rem 15px;
    background: #fff;
}

.copyrights {
    font-size: 14px;
    text-align: center;
}

.copyrights a{
    text-decoration: none;
    color: #013d73;
    font-weight: 500;
}

.scroll-up{
    background-color: #013d73;
    border: 1px solid #013d73;
    border-radius: 2px;
    bottom: 30px;
    cursor: pointer;
    color: #ffffff !important;
    height: 40px;
    line-height: 3;
    right: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    position: fixed; 
    z-index: 1;
}

.after-navbar{
    padding-top: 77px;
}

.page-header{
    background: url(./images/weOffer.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-background-size: cover;
    background-size: cover;
    background-attachment: fixed;
    width: 100%;
}

.page-header .page-titles {
    margin-top: 5rem;
    position: relative;
    text-transform: capitalize;
}

.page-heading{
    font-size: 50px;   
    padding-top: 50px; 
}

.breadcrumb-wrapper{
    padding-top: 20px;
    padding-bottom: 30px;
}

.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: .75rem 1rem;
    justify-content: center;
    background-color: transparent;
    margin-bottom: 1rem;
    list-style: none;
    border-radius: .25rem;
}

.breadcrumb-item+.breadcrumb-item {
    padding-left: .5rem;
}

.breadcrumb-item a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
}

.breadcrumb-item a:hover {
    color: #0056b3;
    text-decoration: underline;
}

.breadcrumb-item.active{
    color:#fff
}

.pagination {
    padding-left: 0;
    flex-wrap: wrap;
    list-style: none;
    border-radius: .25rem;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-bottom: 50px;
}

.pagination li{
    margin: 10px 0px;
}

.pagination li > a {
    height: 35px;
    width: 35px;
    padding: 0;
    font-size: 18px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #6c757d;
    text-align: center;
    margin: 0 4px;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid #6c757d;
    border-radius: 50% !important;
}

.pagination li.active a{
    color: #fff;
    border: 1px solid #013d73;
    background-color: #013d73;
    pointer-events: none;
    cursor: none;
}

.pagination li.disabled a{
    color: #fff;
    background-color: #6c757d;
    cursor: not-allowed;
}

.loader{
    text-align: center;
    padding:"20px 0px";
    text-align:center;
}

.no-data{
    color:red;
    padding:"20px 0px";
    text-align:center;
}

.link{
    color:#0000ee;
    background-color: transparent;
}

.link:hover{
    color:#013d73;
}

.modal-title{
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: 400;   
}

.m-title{
  text-align:left;
}

.rodal-dialog {
  z-index: 10000000 !important;
  height: fit-content !important;
}

.rodal-content {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
}

.rodal-actions {
    display: flex;
    justify-content: flex-end;
}

.news_item {
    background: #fff;
    margin:0px 20px;
    box-shadow: 0 0px 5px 0px rgba(0, 0, 0, 0.1);
}

.news_item .news_desc {
    padding: 10px 30px 40px 30px;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    min-height: 320px;
}

.news_item h2{
    color:#5d5c5c;
    font-size: 3.5rem;
    text-transform: uppercase;
    padding: 40px 10px 10px 10px;
}

.search_overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.9);
  z-index: 10000;
  cursor: pointer;
  transition: .5s ease;
}

.search_cancel_icon{
    top: 20px;
    width: 25px;
    right: 20px;
    height: 25px;
    position: absolute;
}

.no-search-text{
    color: red;
    margin-top: 10px;
    text-align: center;
    margin-bottom: 15px;
}

.my-404-wrapper{
    display: flex;
    align-items: center;
    height: 100vh;
    justify-content: center;
}

.search-desc > p{
    margin-bottom: 0;
}

.my-404-image{
    width: 100%;
    transform: scale(1);
}

@media screen and (max-width: 992px){
    .process-wrapp {
        text-align: center;
    }
    .process-wrapp li {
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        max-width: 300px;
    }
    .process-wrapp li:first-child::before, 
    .process-wrapp li:last-child::after {
        opacity: 1;
        visibility: visible;
    }
    .news_item h2{
        font-size: 3.2rem;
    }
    .my-404-image{
        transform: scale(1.1);
    }
}

@media screen and (max-width: 1110px){
    .navbar{
        width: 95%;
    }
    .navbar-container{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .nav{
        max-height: 0px;
        flex-wrap: unset;
        flex-direction: column;
    }
    .nav-list{
        padding-top:10px; 
        padding-bottom:15px; 
        padding-left:2%; 
        padding-right:2%; 
        z-index: unset;
    }
    .nav-list:nth-last-child(){
        padding-bottom:10px; 
    }
    .logo-wrapper{
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
    }
    .menu-button div{
        display: block;
    }
    .show{
        max-height: 500px;
        overflow: auto;
        padding-bottom: 28px;
        transition-property: all;
        transition-duration: .5s;
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
	    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    }
    .show::-webkit-scrollbar {
        display: none;
         width: 0px;  /* Remove scrollbar space */
        background: transparent;  /* Optional: just make scrollbar invisible */
    }
    .dropdown-menu-custom{
        width: 100%;
        position: relative;
    }
    .after-navbar{
        padding-top: 66px;
    }
    .carousel-button{
        padding: 12px 30px;
        font-size: 12px;
    }
    .page-heading{
        font-size: 45px;
        padding-top: 40px; 
    }
}

@media screen and (max-width: 768px){
    .text-section{
        background: #013d73;
    }
    .carousel-button{
        padding: 10px 20px;
        font-size: 11px;
    }
    .page-heading{
        font-size: 40px;
        padding-top: 30px; 
    }
    .news_item h2{
        font-size: 3rem;
    }
    .my-404-image{
        transform: scale(1.2);
    }
}

@media screen and (max-width: 440px){
    .icon-counters .img-icon{
        font-size:30px;

    }
    .counters .count_nums{
        font-size:28px;   
    }
    .counters .fa{
        font-size:1rem;   
    }
    .text-section-title{
        font-size:14px;
    }
    .we-offer{
        font-size:30px;
        margin-bottom: 0px; 
    }
    .carousel-button{
        padding: 7px 15px;
        font-size: 10px;
    }
    .page-heading{
        font-size: 30px;
        padding-top: 20px; 
    }
    .news_item{
        margin:0px;
    }
    .news_item h2{
        font-size: 2.5rem;
    }
    .search_cancel_icon{
        width: 20px;
        height: 20px;
    }
    .my-404-image{
        transform: scale(1.5);
    }
} 

